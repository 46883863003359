import React from "react";
import "./Main.scss";

function Main() {
    return (
        <div className="home">
            <h1>Project is still in development</h1>
        </div>
    );
}

export default Main;
